.services {
    margin-top: 150px;
    h2{
        margin: 180px auto 100px auto;
    }
    .mobile-services {
        display: none;
    }

    .service {
        overflow: hidden;
        margin-bottom: 30px;
        position: relative;

        .info {
            display: flex;
            justify-content: center;
            flex-direction: column;
            height: 500px;

            &.left {
                text-align: left;
                float: right;
            }

            &.right {
                text-align: right;
                float: left;
            }
        }

        .image {
            background-size: contain;
            background-repeat: no-repeat;
            height: 500px;
            position: absolute;

            &.left {
                background-position: left;
                left: 0;
            }

            &.right {
                background-position: right;
                right: 0;
            }

        }

        h3 {
            color: black;
            line-height: 35px;
        }
    }

    @media(max-width: 768px) {
        margin-top: 0;

        .mobile-services {
            display: block;
        }

        .service {
            position: unset;

            .info {
                display: block;
                padding: 15px;
                text-align: center;
                height: auto;
                float: none !important;
            }

            .image {
                height: auto;
                background-position: center !important;
                background-size: cover;
                height: 340px;
                padding: 0;
                position: unset;
                background-position: top !important;
            }
        }
    }

}