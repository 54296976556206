.home .head {
  position: relative;
}

.home .head .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.24);
  z-index: 1;
}

.home .head .circle {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -moz-transform: translateY(-50%) translateX(-50%);
  -ms-transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  text-align: center;
  height: 500px;
  width: 500px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-color: rgba(243, 244, 244, 0.95);
  z-index: 2;
}

.home .head .heading {
  z-index: 2;
  display: block;
  /* margin: 0 auto; */
  text-align: center;
  /* top: 34px; */
  /* border-radius: 50%; */
  overflow: hidden;
  width: 50%;
  /* height: 400px; */
  padding: 20px;
  width: 90%;
  top: 110px;
  margin: 0;
  display: inline-block;
}

.home .head .heading h1 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 700;
  color: #f9b409;
}

.home .head .heading h5 {
  font-weight: normal;
  font-size: 16px;
  margin: 30px auto;
}

.home .head .collage {
  height: 700px;
}

.home .head .collage div {
  height: 100%;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}

.home .head .collage div.half-image {
  height: 50%;
}

.home .about {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 50px auto;
}

.home .about .about-info {
  padding-right: 150px;
  padding-left: 0;
}

.home .about .about-info span {
  color: #f9b409;
}

.home .about .about-pic {
  height: 600px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.home .about .about-pic img {
  width: 90%;
}

.home .values ul {
  display: flex;
  justify-content: center;
}

.home .values ul li img {
  width: 80px;
}

.home .values ul li h4 {
  width: 40%;
  margin: 30px auto;
}

.home .values ul li p {
  width: 90%;
  margin: 0 auto;
  font-size: 14px;
}

.home .top-title {
  margin-bottom: 20px;
  display: block;
  text-align: center;
}

.home .top-products {
  position: relative;
  margin-bottom: 100px;
}

.home .top-products .slick-slider {
  z-index: 2;
  margin: 0 40px;
}

.home .top-products .slick-slider .slick-list {
  padding-bottom: 60px !important;
}

.home .top-products .top-product {
  display: block;
  outline: none;
  margin: 5px;
  position: relative;
}

.home .top-products .top-product .image-holder {
  height: 170px;
  overflow: hidden;
}

.home .top-products .top-product .image-holder img {
  height: 100%;
  width: 100%;
}

.home .top-products .top-product p {
  font-size: 13px;
  font-weight: 500;
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: -60px;
  height: 80px;
  margin: 0;
  text-align: center;
  background-color: white;
  border-bottom: 4px solid #ffc000;
  font-weight: 700;
  letter-spacing: 1px;
  padding: 20px;
  backface-visibility: hidden;
  -webkit-backface-visibility: visible;
  -moz-backface-visibility: visible !important;
  -o-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
}

.home .top-products .slick-slide:focus {
  outline: none;
}

@media (max-width: 768px) {
  .home .head .circle {
    width: 320px;
    height: 320px;
  }
  .home .head .heading {
    top: 30px;
    width: 100%;
  }
  .home .head .heading h1 {
    font-size: 24px;
  }
  .home .head .heading h5 {
    margin: 10px auto;
  }
  .home .head .collage {
    height: 700px;
  }
  .home .head .collage div.header-one {
    height: 350px;
  }
  .home .top-title {
    width: 90%;
    margin: 20px auto;
  }
  .home .top-products .slick-slider {
    margin: 0;
  }
  .home .about {
    display: block;
    overflow: hidden;
  }
  .home .about .about-info {
    padding: 0 10px;
    text-align: center;
    float: none;
    margin-bottom: 15px;
  }
  .home .about .about-pic img {
    width: 100%;
    float: none;
  }
  .home .values ul {
    display: block;
  }
  .home .values ul li {
    margin: 10px 0;
  }
  .home .values ul li p {
    font-size: 10px;
  }
  .home .values ul li h4 {
    width: auto;
    font-weight: normal;
    font-size: 14px;
    margin: 10px auto;
  }
  .home .values ul li img {
    width: 60px;
  }
  .home .small-video {
    display: block;
  }
}
