.prices {
  margin-top: 150px;
  margin-bottom: 50px;
}

.prices h4 {
  font-weight: normal;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.prices p {
  display: none;
}

.prices .table-responsive {
  border: none;
}

.prices .table-responsive table th {
  font-weight: bolder;
  text-transform: uppercase;
  background-color: #f9b409;
}

.prices .table-responsive table tr td {
  border: none !important;
}

.prices .table-responsive table tr td:last-child {
  padding-right: 0;
  padding-left: 0;
}

.prices .prices-list-mobile {
  display: none;
}

.prices .prices-list-mobile .price-block {
  margin-bottom: 5px;
}

.prices .prices-list-mobile .price-block p {
  margin-bottom: 0;
  padding: 2px;
}

.prices .prices-list-mobile .price-block li.bold {
  background-color: #f9b409;
}

@media (max-width: 768px) {
  .prices {
    padding: 0 30px;
    margin-top: 80px;
  }
  .prices p {
    display: block;
  }
  .prices table th {
    font-size: 14px;
  }
  .prices table td {
    font-size: 12px;
  }
  .prices .prices-list-mobile {
    display: block;
  }
}
