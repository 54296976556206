#faq {
  margin: 100px 0;
  overflow: hidden;
}

#faq .faq-heading {
  margin: 50px auto;
}

#faq .card {
  margin-bottom: 20px;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
}

#faq .card .card-header {
  background-color: #f5f5f5;
  border-radius: 4px;
}

#faq .card .card-header h2 {
  margin: 0;
}

#faq .card .card-body {
  padding: 20px;
}

#faq .card button {
  color: #333;
  font-weight: 700;
  font-size: 16px;
  padding: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  padding-right: 30px;
  white-space: normal;
  text-align: left;
  position: relative;
}

#faq .card button i {
  position: absolute;
  right: 20px;
}

@media (max-width: 840px) {
  #faq .card button {
    padding: 10px;
    padding-right: 30px;
  }
  #faq .card button i {
    right: 10px;
  }
}

#faq .card button:active, #faq .card button:focus, #faq .card button:hover {
  outline: none;
  text-decoration: none;
}

#faq .contacts-faq {
  border-left: 3px solid #fab409;
  padding-left: 25px;
}
