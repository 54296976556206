.mobile-nav {
    $white: #fefefe;
    $clouds: #ecf0f1;
    $concrete: #95a5a6;
    $black: #333;
    $bg: $clouds;
    $bg-menu: $white;
    $c: $black;
    $line-width: 4px;
    $menu-width: 100%;
    $transition: 0.4s ease;
    // overflow-x: scroll;
    // padding-bottom: 100px;

    border-bottom: 1px solid #E3E3E3;

    position: fixed;
    top: 67px;
    overflow: hidden;
    height: 50px;
    width: 100%;
    background-color: white;
    z-index: 9;




.toggle {
    &__input {
        position: absolute;
        width: 1px;
        height: 1px;
        opacity: 0;
    }

    &__label {
        position: absolute;
        z-index: 4;
        padding: 6px;
        cursor: pointer;
        border: none;
        text-transform: uppercase;
        font-size: 11px;
        color: #f9b409;
        padding: 10px 25px;
        // top: 67px;
        top: 0;
        background-color: white;

        span {
            display: inline-block;
            position: relative;
            width: 20px;
            height: 20px;
            transition: 0.4s ease;
            vertical-align: middle;
            margin-right: 10px;
            background-color: #f9b407;
            border-radius: 50px;
            padding: 14px;
            text-align: center;
            -webkit-box-shadow: 0px 0px 12px 1px rgba(194, 194, 194, 1);
            -moz-box-shadow: 0px 0px 12px 1px rgba(194, 194, 194, 1);
            box-shadow: 0px 0px 10px -2px rgba(194, 194, 194, 1);

            &:before,
            &:after {
                content: "";
                position: absolute;
                display: block;
                width: 100%;
                height: 2px;
                background: #ffffff;
                transition: 0.4s ease;
                left: 6px;
            }


            &:before {
                top: 9px;
                width: 44%;
            }

            &:after {
                top: 16px;
                width: 60%;
            }

        }
    }
}

.toggle-categories {
    display: block;
}

.result {
    display: none;
}

// Style nav
.nav {
    position: fixed;
    z-index: 3;
    width: $menu-width;
    height: 100%;
    // padding-top: 45px;
    overflow-y: auto;
    background: $bg-menu;
    transform: translateX(-$menu-width);
    transition: transform $transition;
    padding-bottom: 100px;

    padding-top: 60px;
}

// Style overlay
.o-mask {
    position: fixed;
    z-index: 1;
    top: 50px;
    left: 0;
    width: 0;
    height: 0;
    overflow: hidden;
    background: #d0d0d0;
    opacity: 0;
    transition: opacity $transition, width 0s $transition, height 0s $transition;
}

// Global wrapper
.g-wrapper {
    position: relative;
    width: 100%;
    min-height: 100%;
    padding: 20px;
    padding-top: 60px;
    transition: transform $transition;
    margin-top: 150px;
}

.calculator-form {
    font-size: 16px;
}

// Checked
.toggle__input:checked {
    &+.toggle__label {
        // position: fixed;

        span {
            border-color: transparent;

            &:before {
                transform: rotate(-45deg);
                top: 13px;
                width: 60%;
            }

            &:after {
                transform: rotate(45deg);
                top: 14px;
                width: 60%;
            }
        }
    }

    &~.nav {
        transform: translateX(0);
    }

    &~.o-mask {
        width: 100%;
        height: 100%;
        opacity: 0.7;
        transition: opacity $transition;
    }

    &~.g-wrapper {
        transform: translateX($menu-width);
    }
}


}

.products {
    overflow: hidden;
    // margin-top: 150px;
}

.filters {
    margin-bottom: 30px;
    overflow: hidden;

    div {
        padding: 0;
        margin-right: 12px;

        select {
            -webkit-appearance: none;
            appearance: none;
            background-color: white;
            border: 1px solid;
            border-radius: 0;
            // font-size: 13px;
            font-size: 14px;
            padding: 5px;
            background-image: url('/img/products/arrow-down.png');
            background-repeat: no-repeat;
            background-size: 12px;
            background-position: 96% 12px;
            width: 100%;
        }

        &.button-filter {
            margin-right: 0;

            button {
                width: 100%;
            }
        }
    }
}

.categories {
    &.desktop {
        li {
            padding: 5px;
            cursor: pointer;

            &:hover, &.bckgr {
                background: rgba(215, 215, 215, 0.51);
            }
        }

    }

    li {
        display: block;
        width: 100%;

        &.subcategory {
            a {
                font-size: 12px;
            }
        }

        &.active {
            a {
                color: #ffc000 !important;
            }
        }

        a {
            text-transform: uppercase;
            font-weight: 500;
            line-height: 31px;
            // font-size: 12px;
            font-size: 14px;
            width: 100%;
            height: 100%;
            display: block;


            &.open-lis {
                i {
                    -webkit-animation: rotate-back 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                    animation: rotate-back 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

                    &.rotate {
                        -webkit-animation: rotate-90-ccw 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                        animation: rotate-90-ccw 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                    }
                }

                &.active-category {
                    color: #ffc000;
                    font-weight: 600;
                }
            }

            &:hover {
                // color: #ffc000;
                // background-color: rgb(215 215 215 / 51%);

            }
        }

        ul {
            display: none;

            &.show-listing {
                display: block;
                -webkit-animation: scale-in-ver-top 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                animation: scale-in-ver-top 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            }
        }
    }
}

.hover-subcategory-menu {
    position: fixed;
    z-index: 10;
    background-color: white;
    // height: 100vh;
    top: 118px;
    padding-top: 88px;
    padding-bottom: 88px;

    display: none;
    padding-left: 100px;
    margin-left: -15px;
    // left: 0;
    bottom: 0;

    // display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;
    // align-items: flex-start;
    padding-right: 100px;

    -webkit-box-shadow: -10px 0px 10px -14px rgba(196, 196, 196, 1);
    -moz-box-shadow: -10px 0px 10px -14px rgba(196, 196, 196, 1);
    box-shadow: -10px 0px 10px -14px rgba(196, 196, 196, 1);

    overflow: scroll;

    .category-info {
        // display: flex;
        // flex-wrap: wrap;
        // flex: 0 0 50%;
        border-left: 3px solid #fab409;
        padding-left: 25px;

        .category-image {
            display: block;
            width: 100%;
        }

        .subcategory-image {
            // display: block;
            width: 100%;
        }
    }

    .subcat-wrap {
        // display: flex;
        // flex-wrap: wrap;
        // flex: 0 0 50%;
    }

    .category-header {
        text-transform: uppercase;
        font-weight: 600;
        position: relative;
        // flex: 0 0 100%;

        // &::after {
        //     content: "";
        //     position: absolute;
        //     width: 110%;
        //     height: 2px;
        //     background-color: #f9b409;
        //     left: 0;
        //     top: 30px;
        // }

    }

    li {
        padding: 5px 0;

        &.subcategory {

            a {
                font-size: 12px;
                font-weight: 500;
            }
        }

        &.active {
            a {
                color: #ffc000 !important;
            }
        }
    }


}

@-webkit-keyframes scale-up-center {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    100% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }
}

@keyframes scale-up-center {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    100% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }
}

@-webkit-keyframes scale-in-center {
    0% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes scale-in-center {
    0% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}


.products-container {
    h4 {
        margin-top: 0;
        margin-bottom: 30px;
        font-weight: normal;
        text-transform: capitalize;
    }

    .product {
        position: relative;
        // height: 275px;
        // height: 330px;
        // height: 370px;
        height: 430px;
        padding-right: 15px;
        // padding: 2px;
        margin-bottom: 20px;
        display: inline-block;
        overflow: hidden;
        cursor: pointer;


        .image-holder {
            // height: 145px;
            height: 165px;
            overflow: hidden;

            img {
                height: 100%;
                width: 100%;
                -webkit-animation: scale-in-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
                animation: scale-in-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

                &:hover {
                    -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
                    animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
                }
            }


        }

        h6 {
            text-transform: uppercase;
            font-weight: normal;
            margin: 15px 0;
            font-size: 14px;
            height: 30px;
        }

        p {
            &.price {
                text-transform: uppercase;
                // font-size: 10px;
                font-size: 14px;
                font-weight: 600;
                // margin-top: 15px;

                background: rgba(51, 51, 51, 0.51);
                color: white;
                margin: 0;
                text-align: center;
                margin-bottom: 0;
                width: 260px;
                margin-top: 15px;
                padding: 6px 12px;

                span {
                    color: #f9b409;

                }
            }
        }

        .product-info {
            padding: 0;

            ul {
                height: 100px;
                overflow: visible;

                li {
                    text-transform: uppercase;
                    display: block;
                    // font-size: 10px;
                    // font-size: 12px;
                    font-size: 13px;
                    font-weight: normal;
                }
            }
        }

        button {
            position: absolute;
            // bottom: 15px;
            bottom: 21px;
            left: 0;
            margin: 0;
            text-align: center;
            margin-bottom: 0;
            width: 260px;
            margin-top: 15px;
            padding: 6px 12px;
            // left: 15px;
        }

        &.main-page {
            padding: 0 15px;
            transition: all .4s ease-in-out;

            .overlay {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                // background: #1a1a1a;
                background: white;
                opacity: 0;
                transition: all .15s ease-in-out;

            }

            @-webkit-keyframes slide-in-bottom {
                0% {
                    -webkit-transform: translateY(0);
                    transform: translateY(0);
                    opacity: 0;
                }

                100% {
                    -webkit-transform: translate(50%, -200px);
                    transform: translate(50%, -200px);
                    opacity: 1;
                }
            }

            @keyframes slide-in-bottom {
                0% {
                    -webkit-transform: translateY(0);
                    transform: translateY(0);
                    opacity: 0;
                }

                100% {
                    -webkit-transform: translate(50%, -200px);
                    transform: translate(50%, -200px);
                    opacity: 1;
                }
            }

            button {
                width: 152px;
                left: 15px;
            }

            &:hover {
                color: black;

                .overlay {
                    opacity: 0.5;
                    transition: all .15s ease-in-out;
                    -webkit-box-shadow: 0px 0px 12px 0px rgba(196, 196, 196, 1);
                    -moz-box-shadow: 0px 0px 12px 0px rgba(196, 196, 196, 1);
                    box-shadow: 0px 0px 12px 0px rgba(196, 196, 196, 1);
                    z-index: 1;
                }

                button {
                    opacity: 1;
                    // transform: translate(50%, -215px);
                    z-index: 1;
                    left: 0;
                    bottom: 0;
                    transition: all .15s ease-in-out;
                    -webkit-box-shadow: 0px 0px 12px 0px rgba(196, 196, 196, 1);
                    -moz-box-shadow: 0px 0px 12px 0px rgba(196, 196, 196, 1);
                    box-shadow: 0px 0px 12px 0px rgba(196, 196, 196, 1);
                    -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                    animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                }

            }
        }

        &.product-display {
            height: auto;

            a {
                // margin: 5px 5px 0 0;
                margin-top: 4px;

                &.small-images {
                    overflow: hidden;
                    height: 90px;
                    // width: 90px;
                    display: inline-block;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                }

            }

            .top-products {
                position: relative;
                margin-bottom: 0;

                .slick-slider {
                    z-index: 2;
                    margin: 0 40px;
                }

                .top-product {
                    // border: 1px solid #e3e3e3;
                    display: block;
                    outline: none;
                    margin: 5px;
                    position: relative;

                    .image-holder {
                        height: 90px;
                        overflow: hidden;

                        &:hover+p {
                            display: block;
                        }
                    }
                }

                .slick-slide {
                    &:focus {
                        outline: none;
                    }
                }

                .carousel-arrows {
                    // position: relative;

                    button {
                        bottom: 30px;
                    }

                    .slick-arrow {
                        position: absolute;

                        i {
                            padding: 10px;

                        }

                        &.prev {
                            left: 0;
                        }

                        &.next {
                            right: 0;
                        }
                    }
                }
            }



        }
    }

    .single-product {
        h3 {
            margin-bottom: 30px;
        }

        .product {
            .image-holder {
                height: auto;

                img {
                    -webkit-animation: none;
                    animation: none;

                    &:hover {
                        -webkit-animation: none;
                        animation: none;
                    }
                }
            }

            .product-info,
            .price {
                display: inline-block;
                font-size: 14px;
                // font-size: 12px;
                padding-left: 10px;

                ul {
                    line-height: 27px;

                    li {
                        // font-size: 12px;
                        font-size: 14px;

                    }
                }
            }

            .price {
                padding-left: 0;
                background-color: transparent;
                // color: #f9b409;
                width: auto;
                color: black;

                &span {
                    color: #f9b409;

                }
            }
        }

        .carousel-arrows {
            display: block;

            .slick-arrow {
                position: relative !important;
                display: block;
                bottom: auto;
                // top: 21px;
                width: auto;

                &.prev {
                    float: left;
                }

                &.next {
                    float: right;
                }
            }
        }
    }
}

#product {
    &.contact-form {
        padding: 0;
    }
}

@media(min-width: 841px) {
    .mobile-nav {
        display: none;

        .toggle-categories {
            display: none;
        }
    }
}

@media(max-width: 840px) {
    .products {
        .products-container {
            display: block;
            float: none !important;
            margin: 0 auto;
            width: 90%;
        }
    }

}

@media(min-width: 840px) {
    .single-product {
        .carousel-arrows {
            top: auto;
            width: auto;
            bottom: 20px;

            .slick-arrow {
                top: auto;
                bottom: auto !important;
                width: auto;
            }
        }
    }

}

@media(max-width: 768px) {
    .mobile-nav {
        .g-wrapper {
            padding: 0;
        }
    }

    .categories {
        padding-left: 30px;
    }

    .products-container {
        .product {
            height: auto;
            padding-right: auto;


            p {
                font-size: 11px;

                &.price {
                    font-size: 14px;
                }
            }

            .product-info {
                ul {
                    li {
                        font-size: 11px !important;
                    }
                }
            }

            button {
                // bottom: 0;
                position: unset;
            }
        }
    }

    .single-product {
        margin-top: 30px;

        .product {
            .price {
                font-size: 11px !important;
                text-align: left;
                padding: 0;
            }
        }

        .carousel-arrows {
            width: auto;
            top: auto;
            // position: relative;
            bottom: 0;

            button {
                bottom: 30px;
            }

            .slick-arrow {
                position: absolute;

                i {
                    padding: 10px;

                }

                &.prev {
                    left: 0;
                }

                &.next {
                    right: 0;
                }
            }
        }

    }

    .products-container .product {
        padding: 15px;

        button {
            right: 0;

            &.prev {
                left: 0;
            }

            &.right {
                right: 0;
            }
        }
    }

    .filters {
        div {
            margin-bottom: 10px;
        }
    }

}

@media (min-width: 768px) {
    .filters {
        div {
            margin-bottom: 10px;
        }
    }
}