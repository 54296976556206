.contacts{
    margin-top: 150px;
    #map-canvas{
        height: 500px;
    }
    .contacts-holder{
        overflow: hidden;
        h3{
            color: black;
            margin-bottom: 30px;
            margin-top: 0;
        }
        p{
            margin: 0;
        }

        .working-hours{
            margin-bottom: 30px;
        }
        ul{
            li{
                margin-bottom: 20px;
                .icon{
                    color: #f9b409;
                    font-size: 40px;
                    margin-right: 20px;
                    vertical-align: top;
                    display: inline-block;
                }
                .info{
                    vertical-align: top;
                    display: inline-block;
                }
            }
            
        }
    }



    @media(max-width: 768px) {
        margin-top: 100px;
        .contacts-holder{
            text-align: center;
            ul{
                margin-bottom: 30px;
                li{
                    .icon{
                        display: block;
                        margin: 0;
                    }
                }
            }
          
        }
        .contact-form{
            padding: 0 20px;
        }
    }
}