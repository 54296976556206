.home {
    .head {
        position: relative;
        .overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0; // background-color: rgba(0, 0, 0, 0.33);
            // background-color: rgba(255, 255, 255, 0.61);
            // background-color: rgba(117, 117, 117, 0.21); 
            background-color: rgba(0, 0, 0, 0.24);
            z-index: 1;
            // .heading {
            //     color: black;
            //     margin-top: 150px;
            //     padding-right: 120px;
            // }
        }
        .circle{
            position: absolute;
            top: 50%;
            left: 50%;
            -moz-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            -moz-transform: translateY(-50%) translateX(-50%);
            -ms-transform: translateY(-50%) translateX(-50%);
            -webkit-transform: translateY(-50%) translateX(-50%);
            transform: translateY(-50%) translateX(-50%);
            text-align: center;
            height: 500px;
            width: 500px;
            -moz-border-radius: 50%;
            -webkit-border-radius: 50%;
            border-radius: 50%;
            // background-color: rgba(255, 194, 34, 0.76);
            background-color: rgba(243,244,244,0.95);
            z-index: 2;
        }
        .heading {
            // position: absolute;
            z-index: 2;
            display: block;
            /* margin: 0 auto; */
            // left: 50px;
            // right: 0;
            text-align: center;
            /* top: 34px; */
            // bottom: 70px; // background-color: #f9b409;
            /* border-radius: 50%; */
            overflow: hidden;
            width: 50%;
            /* height: 400px; */
            // text-align: left;
            padding: 20px;
            width: 90%;
            top: 110px;
            margin: 0;
            display: inline-block;
            h1 {
                margin-top: 10px;
                margin-bottom: 10px;
                font-weight: 700;
                color: #f9b409;
            }
            h5 {
                // font-weight: 500;
                font-weight: normal;
                font-size: 16px;
                margin: 30px auto;
            }
        }
        .collage{
            height: 700px;
            div{
                height: 100%;
                background-size: cover;
                background-position: bottom;
                background-repeat: no-repeat;
                &.half-image{
                    height: 50%;
                }
            }
        }
    }
    .about {
        display: flex;
        align-items: center;
        flex-direction: row; // margin: 20px 0;
        margin: 50px auto;
        .about-info {
            padding-right: 150px;
            padding-left: 0;
            span {
                color: #f9b409;
            }
        }
        .about-pic {
            height: 600px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            img {
                width: 90%;
            }
        }
    }
    .values {
        ul {
            display: flex;
            justify-content: center;
            li {
                img {
                    width: 80px;
                }
                h4 {
                    width: 40%;
                    margin: 30px auto;
                }
                p {
                    width: 90%;
                    margin: 0 auto;
                    // font-size: 12px;
                    font-size: 14px;
                }
            }
        }
    }
    .top-title {
        margin-bottom: 20px;
        display: block; // width: 40%;
        text-align: center;
    }
    .top-products {
        position: relative;
        margin-bottom: 100px;
        .slick-slider {
            z-index: 2;
            margin: 0 40px;
            .slick-list{
                padding-bottom: 60px!important;
            }
        }
        .top-product {
            // border: 1px solid #e3e3e3;
            display: block;
            outline: none;
            margin: 5px;
            position: relative;

            .image-holder {
                // height: 150px;
                height: 170px;
                overflow: hidden;
                img {
                    height: 100%;
                    width: 100%;
                }
                // &:hover+p {
                //     display: block;
                // }
            }
            p {
                font-size: 13px;
                font-weight: 500;
                position: absolute;
                left: 10px;
                right: 10px;
                // bottom: -5px;
                bottom: -60px;
                height: 80px;
                margin: 0;
                text-align: center;
                background-color: white;
                border-bottom: 4px solid #ffc000;
                font-weight: 700;
                letter-spacing: 1px;
                padding: 20px;
                // display: none;
                backface-visibility: hidden;
                -webkit-backface-visibility: visible;
                -moz-backface-visibility: visible !important;
                -o-backface-visibility: hidden;
                -ms-backface-visibility: hidden;
                -webkit-font-smoothing: antialiased;
                -moz-font-smoothing: antialiased;
                -ms-font-smoothing: antialiased;
                -o-font-smoothing: antialiased;
                font-smoothing: antialiased;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                -webkit-appearance: none;
                -moz-appearance: none;
                -ms-appearance: none;
                -o-appearance: none;
                // -webkit-animation: flip-in-hor-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                // animation: flip-in-hor-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            } // span{
            //     color: #bfbdbd;
            //     text-transform: none;
            //     font-weight: 400;
            //     &.learn-more{
            //         font-size: 12px;
            //         display: block;
            //         padding: 0 10px 5px 10px;
            //     }
            // }
        }
        .slick-slide {
            &:focus {
                outline: none;
            }
        }
    }
    @media(max-width: 768px) {
        .head {
            .circle{
                width: 320px;
                height: 320px;
            }
            .heading {
                top: 30px;
                width: 100%;

                h1{
                    font-size: 24px;
                }
                h5{
                    margin: 10px auto;
                }
            //     width: auto;
            //     left: 0;
            //     position: relative;
            //     bottom: auto;
            //     background-color: #f9b409;
            //     padding: 10px;
            //     text-align: center;
            }
            .collage{
                height: 700px;
                div{
                    &.header-one{
                        height: 350px;
                    }
                }
            }
        }
        .top-title{
            width: 90%;
            margin: 20px auto;
        }
        .top-products{
            .slick-slider{
                margin: 0;
            }
        }
        .about {
            display: block;
            overflow: hidden;
            .about-info {
                padding: 0 10px;
                text-align: center;
                float: none;
                margin-bottom: 15px;
            }
            .about-pic {
                img {
                    width: 100%;
                    float: none;
                }
            }
        }
        .values ul {
            display: block;
            li {
                margin: 10px 0;
                p{
                    font-size: 10px;
                }
                h4{
                    width: auto;
                    font-weight: normal;
                    font-size: 14px;
                    margin: 10px auto;
                }
                img{
                    width: 60px;
                }
            }
        }
        .small-video{
            display: block;
        }
    }
}