body {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: normal;
  overflow-x: hidden;
}

#top {
  display: none;
  position: fixed;
  right: 75px;
  bottom: 48px;
  font-size: 42px;
  color: #ffc000;
  z-index: 1000;
}

#top i {
  border: 1px solid #ffffff;
  border-radius: 50px;
  background-color: white;
}

.pagination {
  visibility: hidden;
}

a {
  text-decoration: none;
  color: black;
  cursor: pointer;
}

a:hover {
  text-decoration: none;
  color: auto;
  color: #747474;
}

a:focus {
  outline: none;
  text-decoration: none;
}

p {
  color: black;
  font-size: 16px;
}

ul {
  display: block;
  overflow: hidden;
  margin-bottom: 0;
  padding-left: 0;
}

li {
  text-decoration: none;
  list-style-type: none;
  float: left;
}

.block {
  display: block;
}

select {
  outline: none;
}

.yellow-line {
  border-top: 1px solid #f9b409;
}

.red {
  color: red;
}

.modal-dialog {
  width: 60%;
}

.modal-dialog .close {
  font-size: 50px;
  font-weight: lighter;
  opacity: 1;
}

.modal-dialog .modal-content {
  overflow: hidden;
}

.modal-dialog .modal-content .modal-header {
  border: none;
  margin-bottom: 30px;
}

.modal-dialog .modal-content h4 {
  text-align: center;
  text-transform: uppercase;
  color: black;
  margin-bottom: 20px;
}

.modal-dialog .modal-content .products-container {
  overflow: hidden;
  display: block;
  margin: 0 40px;
}

.modal-dialog .modal-content .products-container .product {
  height: auto;
  margin: 0;
}

.modal-dialog .modal-content .products-container .product .image-holder {
  height: auto;
  width: 100%;
}

.modal-dialog .modal-content .products-container .product .product-info .price {
  font-size: 12px;
}

.modal-dialog .modal-content .products-container .product .product-info li {
  font-size: 12px;
  margin: 10px auto;
}

.modal-dialog .modal-content .modal-footer {
  border: none;
}

.modal-dialog .modal-content .modal-footer .contact-form {
  margin: 0 0 50px 0;
  overflow: hidden;
  padding: 0px 35px;
}

.modal-dialog .modal-content .modal-footer .contact-form h4 {
  text-align: left;
  text-transform: none;
  font-weight: 200;
}

.modal-dialog .modal-content .modal-footer .contact-form .btn {
  margin-left: 15px;
}

.contact-form {
  margin: 10px 0;
  overflow: hidden;
  padding: 0 200px;
}

.contact-form.calculator {
  margin: 0;
}

.contact-form.calculator .form-control {
  padding: 6px 2px;
}

.contact-form.calculator .form-control::placeholder {
  font-size: 20px;
}

.contact-form.calculator .btn {
  position: relative;
  top: 27px;
  width: auto;
  margin-left: 15px;
}

.contact-form h3 {
  margin: 50px 0 30px 0;
}

.contact-form textarea {
  resize: none;
}

.contact-form .form-control {
  border-radius: 0;
  border-color: #ccc;
  border-width: 0 0 2px 0;
  border-style: none none solid none;
  box-shadow: none;
}

.contact-form .form-control:focus {
  box-shadow: none;
  border-color: #f9b409;
}

.contact-form .js-hide-label {
  opacity: 0;
}

.contact-form .js-unhighlight-label {
  color: #999;
}

.contact-form button {
  display: block;
  margin: 0 auto;
  width: 200px;
}

.info-calc {
  font-size: 12px;
  margin-bottom: 20px;
}

.styled-checkbox {
  position: absolute;
  opacity: 0;
  border-radius: 8px;
  outline: none;
}

.styled-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
}

.styled-checkbox + label:before {
  content: '';
  margin-right: 5px;
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  height: 15px;
  background: white;
  border-radius: 5px;
  bottom: 1px;
  position: relative;
}

.styled-checkbox:hover + label:before {
  background: #747474;
}

.styled-checkbox:focus + label:before {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}

.styled-checkbox:checked + label:before {
  background: #747474;
}

.styled-checkbox:disabled + label:before {
  box-shadow: none;
  background: #ddd;
}

.styled-checkbox:checked + label:after {
  content: '';
  position: absolute;
  left: 3px;
  top: 11px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
  transform: rotate(45deg);
}

.content {
  margin-top: 100px;
}

.main-container {
  width: 90%;
  display: block;
  margin: 0 auto;
  overflow: hidden;
}

.no-padding {
  padding: 0;
}

.bold {
  font-weight: 600;
}

.margin-bottom {
  margin-bottom: 70px;
}

.btn.main-button {
  background-color: #f9b409;
  color: white;
  border-radius: 1px;
  outline: none;
  font-weight: normal;
  font-size: 14px;
  border: 1px solid #f9b409;
}

.btn.main-button:hover {
  opacity: 0.8;
}

.info-ribbon {
  background-color: #f9b409;
  position: fixed;
  z-index: 5;
  font-size: 17px;
  padding: 1px 0;
}

.info-ribbon .econt img {
  width: 14px;
  position: relative;
  top: -1px;
}

.info-ribbon .pull-left .icon {
  margin-right: 3px;
}

.info-ribbon .pull-left p {
  display: inline-block;
  margin: 0;
  color: white;
  font-weight: 700;
}

.info-ribbon .pull-right p {
  margin: 0;
  display: inline-block;
}

.info-ribbon a {
  color: white;
  margin-right: 10px;
}

.navigation-main {
  background-color: white;
  color: black;
  padding: 10px 0;
  line-height: 68px;
  position: fixed;
  z-index: 5;
  top: 26px;
  border-bottom: 1px solid #E3E3E3;
}

.navigation-main .logo {
  width: 130px;
}

.navigation-main .menu {
  text-align: center;
  font-size: 19px;
}

.navigation-main .menu li {
  margin: auto 10px;
  display: inline-block;
  float: none;
}

.navigation-main .menu li a {
  font-weight: bold;
}

.navigation-main .menu li a:hover {
  text-decoration: none;
  color: #747474;
}

.navigation-main .menu li a.active {
  border-bottom: 2px solid #f9b409;
}

.search-main {
  overflow: hidden;
  height: 50px;
}

.search-main .search-input {
  height: 60px;
  font-size: 14px;
  display: inline-block;
  border: none;
  outline: none;
  padding: 3px;
  padding-right: 60px;
  width: 0px;
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  z-index: 3;
  transition: width 0.4s cubic-bezier(0, 0.795, 0, 1);
  cursor: pointer;
}

.search-main .search-input:focus {
  width: 150px;
  z-index: 1;
  border-bottom: 1px solid #BBB;
  cursor: text;
}

.search-main .search-input:focus:hover {
  border-bottom: 1px solid #BBB;
}

.search-main .search-btn {
  width: 63px;
  display: inline-block;
  background: url("../../../img/search-icon.png") center center no-repeat;
  background-size: 23px;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  cursor: pointer;
  transition: opacity .4s ease;
  outline: none;
}

.search-main .search-btn:hover {
  opacity: 0.8;
}

.responsive-menu {
  position: fixed;
  display: none;
  top: 25px;
  left: 0;
  right: 0;
  height: 50px;
  background: white;
  overflow: hidden;
  transition: all 0.5s ease-out, background 1s ease-out;
  transition-delay: 0.2s;
  z-index: 10;
  border-bottom: 1px solid #E3E3E3;
}

.responsive-menu a {
  position: relative;
  top: 7px;
}

.responsive-menu a .logo {
  width: 60px;
  float: right;
}

.responsive-menu .burger-container {
  position: relative;
  display: inline-block;
  height: 50px;
  width: 50px;
  cursor: pointer;
  transform: rotate(0deg);
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.responsive-menu .burger-container #burger {
  width: 18px;
  height: 8px;
  position: relative;
  display: block;
  margin: -4px auto 0;
  top: 50%;
}

.responsive-menu .burger-container #burger .bar {
  width: 100%;
  height: 1px;
  display: block;
  position: relative;
  background: black;
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition-delay: 0s;
}

.responsive-menu .burger-container #burger .bar.topBar {
  transform: translateY(0px) rotate(0deg);
}

.responsive-menu .burger-container #burger .bar.btmBar {
  transform: translateY(6px) rotate(0deg);
}

.responsive-menu .icon {
  display: inline-block;
  position: absolute;
  height: 100%;
  line-height: 50px;
  width: 50px;
  height: 50px;
  text-align: center;
  color: #FFF;
  font-size: 22px;
  left: 50%;
  transform: translateX(-50%);
}

.responsive-menu .icon.icon-bag {
  right: 0;
  top: 0;
  left: auto;
  transform: translateX(0px);
  transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition-delay: 0.65s;
}

.responsive-menu ul.menu {
  position: relative;
  display: block;
  padding: 0px 48px 100px;
  list-style: none;
}

.responsive-menu ul.menu li.menu-item {
  display: block;
  border-bottom: 1px solid #f9b409;
  margin-top: 5px;
  transform: scale(1.15) translateY(-30px);
  opacity: 0;
  transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99), opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);
}

.responsive-menu ul.menu li.menu-item:nth-child(1) {
  transition-delay: 0.49s;
}

.responsive-menu ul.menu li.menu-item:nth-child(2) {
  transition-delay: 0.42s;
}

.responsive-menu ul.menu li.menu-item:nth-child(3) {
  transition-delay: 0.35s;
}

.responsive-menu ul.menu li.menu-item:nth-child(4) {
  transition-delay: 0.28s;
}

.responsive-menu ul.menu li.menu-item:nth-child(5) {
  transition-delay: 0.21s;
}

.responsive-menu ul.menu li.menu-item:nth-child(6) {
  transition-delay: 0.14s;
}

.responsive-menu ul.menu li.menu-item:nth-child(7) {
  transition-delay: 0.07s;
}

.responsive-menu ul.menu li.menu-item a {
  display: block;
  position: relative;
  color: black;
  text-decoration: none;
  font-size: 15px;
  line-height: 3.35;
  font-weight: 500;
  width: 100%;
}

.responsive-menu.menu-opened {
  height: 100%;
  background-color: white;
  transition: all 0.3s ease-in, background 0.5s ease-in;
  transition-delay: 0.25s;
  z-index: 10;
}

.responsive-menu.menu-opened .burger-container {
  transform: rotate(90deg);
}

.responsive-menu.menu-opened .burger-container #burger .bar {
  transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition-delay: 0.2s;
}

.responsive-menu.menu-opened .burger-container #burger .bar.topBar {
  transform: translateY(4px) rotate(45deg);
}

.responsive-menu.menu-opened .burger-container #burger .bar.btmBar {
  transform: translateY(3px) rotate(-45deg);
}

.responsive-menu.menu-opened ul.menu li.menu-item {
  transform: scale(1) translateY(0px);
  opacity: 1;
  float: none;
  text-align: center;
}

.responsive-menu.menu-opened ul.menu li.menu-item:nth-child(1) {
  transition-delay: 0.27s;
}

.responsive-menu.menu-opened ul.menu li.menu-item:nth-child(2) {
  transition-delay: 0.34s;
}

.responsive-menu.menu-opened ul.menu li.menu-item:nth-child(3) {
  transition-delay: 0.41s;
}

.responsive-menu.menu-opened ul.menu li.menu-item:nth-child(4) {
  transition-delay: 0.48s;
}

.responsive-menu.menu-opened ul.menu li.menu-item:nth-child(5) {
  transition-delay: 0.55s;
}

.responsive-menu.menu-opened ul.menu li.menu-item:nth-child(6) {
  transition-delay: 0.62s;
}

.responsive-menu.menu-opened ul.menu li.menu-item:nth-child(7) {
  transition-delay: 0.69s;
}

.responsive-menu.menu-opened .icon.icon-bag {
  transform: translateX(75px);
  transition-delay: 0.3s;
}

footer {
  font-size: 16px;
  margin-top: 50px;
}

footer .subscribe {
  background-color: #f9b409;
  text-align: center;
  padding: 15px 0;
  background-image: url("../../../img/texture.png");
  background-position: center -160px;
  background-repeat: no-repeat;
  background-size: cover;
}

footer .subscribe .sign-up {
  font-weight: 600;
}

footer .subscribe label {
  font-weight: lighter;
  margin-bottom: 0;
}

footer .subscribe .input-holder {
  width: 40%;
  margin: 10px auto;
  position: relative;
}

footer .subscribe .input-holder input {
  border-radius: 8px;
  background-color: white;
  outline: none;
  padding: 10px;
  font-weight: normal;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  width: 100%;
}

footer .subscribe .input-holder .sumbit-button {
  position: absolute;
  right: 10px;
  top: 8px;
  background-image: url("../../../img/send.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 25px;
  height: 25px;
  background-color: transparent;
  border: none;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
}

footer .footer-links {
  padding: 40px 0;
  border-bottom: 1px solid #dad7d7;
  margin-bottom: 10px;
  overflow: hidden;
}

footer .footer-links .border-left {
  border-left: 1px solid #f9b409;
}

footer .footer-links .newest a {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}

footer .footer-links .logo {
  width: 190px;
  margin: 0 auto;
  display: block;
}

footer .footer-links ul {
  margin-bottom: 20px;
}

footer .footer-links ul li {
  float: none;
}

footer .footer-links ul li.link-heading {
  color: #f9b409;
  font-weight: 600;
  margin-bottom: 10px;
}

footer .footer-links ul li i {
  font-size: 16px;
  margin-right: 5px;
}

footer .foot-rib {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

footer .foot-rib .rights {
  font-size: 12px;
  color: #747474;
  padding: 10px 0;
}

footer .foot-rib .rights.econt-info img {
  margin-left: 5px;
  width: 90px;
}

.arrows {
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 15px;
}

.arrows.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.arrows.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.carousel-arrows {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.carousel-arrows button {
  appearance: none;
  -webkit-appearance: none;
  border: none;
  outline: none;
  background-color: transparent;
}

.carousel-arrows button.prev {
  display: flex;
  align-self: center;
}

.carousel-arrows button.next {
  display: flex;
  align-self: center;
  margin-left: auto;
}

.carousel-arrows button i {
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 15px;
}

.carousel-arrows button i.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.carousel-arrows button i.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.blog-admin .blog-item-admin {
  overflow: hidden;
  margin-bottom: 100px;
  border-bottom: 3px solid #fab406;
  padding-bottom: 60px;
  border-top: 3px solid #fab406;
  padding-top: 60px;
}

.blog-admin .blog-item-admin .edit-box {
  display: block;
  overflow: hidden;
}

.g-wrapper {
  margin-top: 150px;
}

.advice-a:hover .advice-box-image-holder {
  opacity: 0.7;
}

.advice-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 40px;
}

.advice-box .advice-box-image-holder {
  height: 150px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  flex: 0 0 30%;
  margin-right: 10px;
}

.advice-box .advice-description {
  flex: 0 0 65%;
  border-left: 3px solid #fab406;
  padding: 10px 0 10px 10px;
}

.advice-box .advice-description #see-more {
  margin-top: 10px;
  color: #fab406;
  font-size: 12px;
}

.advice-box .info {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

@-webkit-keyframes rotate-90-ccw {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }
}

@keyframes rotate-90-ccw {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }
}

@-webkit-keyframes rotate-back {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
}

@keyframes rotate-back {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
}

@-webkit-keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}

@keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}

@-webkit-keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
    transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
}

@keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
    transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 1;
  }
}

@media (max-width: 1096px) {
  .navigation-main .menu {
    font-size: 14px;
  }
  .navigation-main .menu li {
    margin: auto 5px;
  }
  .navigation-main .logo {
    width: 90px;
  }
}

@media (max-width: 992px) {
  .contact-form.calculator .btn {
    top: 0;
  }
}

@media (max-width: 840px) {
  h2 {
    font-weight: 600 !important;
    font-size: 16px;
  }
  h5 {
    font-weight: 400 !important;
    font-size: 11px;
  }
  p {
    font-size: 11px;
  }
  .content {
    margin-top: 75px;
  }
  .main-container {
    width: 100%;
  }
  .hide-on-mobile {
    display: none;
  }
  .info-ribbon {
    text-align: center;
  }
  .info-ribbon .pull-left {
    float: none !important;
  }
  .info-ribbon .pull-left a {
    margin: 0;
  }
  .info-ribbon .pull-left p:first-child {
    float: left;
    margin-left: 15px;
  }
  .info-ribbon .pull-left p:last-child {
    float: right;
    margin-right: 15px;
  }
  .responsive-menu {
    display: block;
    top: 17px;
  }
  .home .head .heading h5 {
    font-size: 14px !important;
  }
  #top {
    right: 10px;
  }
  .modal-dialog {
    width: 100%;
  }
  .modal-dialog .products-container {
    margin: 0 10px;
  }
  .modal-dialog .products-container .product-info {
    margin-top: 20px;
  }
  .modal-dialog .modal-footer .contact-form {
    padding: 0 10px;
  }
  .carousel-arrows {
    bottom: 0;
    top: 210px;
    display: block;
    margin: 0 auto;
    width: 100px;
  }
  .carousel-arrows button {
    display: inline-block !important;
  }
  .carousel-arrows button i {
    padding: 9px;
  }
  footer {
    font-size: 12px;
  }
  footer .footer-links .border-left {
    border: none;
  }
  footer .subscribe {
    padding: 10px;
    background-position: center;
  }
  footer .subscribe label {
    width: 90%;
  }
  footer .subscribe .input-holder {
    width: 90%;
    margin-top: 15px;
    font-size: 11px;
  }
  footer .rights {
    padding: 10px;
  }
  footer .newest {
    float: right;
  }
  .advice-box .advice-box-image-holder {
    flex: 0 0 100%;
    margin-right: 10px;
    margin-bottom: 15px;
  }
  .advice-box .advice-description {
    flex: 0 0 100%;
  }
}
