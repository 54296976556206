.mobile-nav {
  border-bottom: 1px solid #E3E3E3;
  position: fixed;
  top: 67px;
  overflow: hidden;
  height: 50px;
  width: 100%;
  background-color: white;
  z-index: 9;
}

.mobile-nav .toggle__input {
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
}

.mobile-nav .toggle__label {
  position: absolute;
  z-index: 4;
  padding: 6px;
  cursor: pointer;
  border: none;
  text-transform: uppercase;
  font-size: 11px;
  color: #f9b409;
  padding: 10px 25px;
  top: 0;
  background-color: white;
}

.mobile-nav .toggle__label span {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  transition: 0.4s ease;
  vertical-align: middle;
  margin-right: 10px;
  background-color: #f9b407;
  border-radius: 50px;
  padding: 14px;
  text-align: center;
  -webkit-box-shadow: 0px 0px 12px 1px #c2c2c2;
  -moz-box-shadow: 0px 0px 12px 1px #c2c2c2;
  box-shadow: 0px 0px 10px -2px #c2c2c2;
}

.mobile-nav .toggle__label span:before, .mobile-nav .toggle__label span:after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  background: #ffffff;
  transition: 0.4s ease;
  left: 6px;
}

.mobile-nav .toggle__label span:before {
  top: 9px;
  width: 44%;
}

.mobile-nav .toggle__label span:after {
  top: 16px;
  width: 60%;
}

.mobile-nav .toggle-categories {
  display: block;
}

.mobile-nav .result {
  display: none;
}

.mobile-nav .nav {
  position: fixed;
  z-index: 3;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: #fefefe;
  transform: translateX(-100%);
  transition: transform 0.4s ease;
  padding-bottom: 100px;
  padding-top: 60px;
}

.mobile-nav .o-mask {
  position: fixed;
  z-index: 1;
  top: 50px;
  left: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  background: #d0d0d0;
  opacity: 0;
  transition: opacity 0.4s ease, width 0s 0.4s ease, height 0s 0.4s ease;
}

.mobile-nav .g-wrapper {
  position: relative;
  width: 100%;
  min-height: 100%;
  padding: 20px;
  padding-top: 60px;
  transition: transform 0.4s ease;
  margin-top: 150px;
}

.mobile-nav .calculator-form {
  font-size: 16px;
}

.mobile-nav .toggle__input:checked + .toggle__label span {
  border-color: transparent;
}

.mobile-nav .toggle__input:checked + .toggle__label span:before {
  transform: rotate(-45deg);
  top: 13px;
  width: 60%;
}

.mobile-nav .toggle__input:checked + .toggle__label span:after {
  transform: rotate(45deg);
  top: 14px;
  width: 60%;
}

.mobile-nav .toggle__input:checked ~ .nav {
  transform: translateX(0);
}

.mobile-nav .toggle__input:checked ~ .o-mask {
  width: 100%;
  height: 100%;
  opacity: 0.7;
  transition: opacity 0.4s ease;
}

.mobile-nav .toggle__input:checked ~ .g-wrapper {
  transform: translateX(100%);
}

.products {
  overflow: hidden;
}

.filters {
  margin-bottom: 30px;
  overflow: hidden;
}

.filters div {
  padding: 0;
  margin-right: 12px;
}

.filters div select {
  -webkit-appearance: none;
  appearance: none;
  background-color: white;
  border: 1px solid;
  border-radius: 0;
  font-size: 14px;
  padding: 5px;
  background-image: url("/img/products/arrow-down.png");
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: 96% 12px;
  width: 100%;
}

.filters div.button-filter {
  margin-right: 0;
}

.filters div.button-filter button {
  width: 100%;
}

.categories.desktop li {
  padding: 5px;
  cursor: pointer;
}

.categories.desktop li:hover, .categories.desktop li.bckgr {
  background: rgba(215, 215, 215, 0.51);
}

.categories li {
  display: block;
  width: 100%;
}

.categories li.subcategory a {
  font-size: 12px;
}

.categories li.active a {
  color: #ffc000 !important;
}

.categories li a {
  text-transform: uppercase;
  font-weight: 500;
  line-height: 31px;
  font-size: 14px;
  width: 100%;
  height: 100%;
  display: block;
}

.categories li a.open-lis i {
  -webkit-animation: rotate-back 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: rotate-back 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.categories li a.open-lis i.rotate {
  -webkit-animation: rotate-90-ccw 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: rotate-90-ccw 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.categories li a.open-lis.active-category {
  color: #ffc000;
  font-weight: 600;
}

.categories li ul {
  display: none;
}

.categories li ul.show-listing {
  display: block;
  -webkit-animation: scale-in-ver-top 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-in-ver-top 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.hover-subcategory-menu {
  position: fixed;
  z-index: 10;
  background-color: white;
  top: 118px;
  padding-top: 88px;
  padding-bottom: 88px;
  display: none;
  padding-left: 100px;
  margin-left: -15px;
  bottom: 0;
  padding-right: 100px;
  -webkit-box-shadow: -10px 0px 10px -14px #c4c4c4;
  -moz-box-shadow: -10px 0px 10px -14px #c4c4c4;
  box-shadow: -10px 0px 10px -14px #c4c4c4;
  overflow: scroll;
}

.hover-subcategory-menu .category-info {
  border-left: 3px solid #fab409;
  padding-left: 25px;
}

.hover-subcategory-menu .category-info .category-image {
  display: block;
  width: 100%;
}

.hover-subcategory-menu .category-info .subcategory-image {
  width: 100%;
}

.hover-subcategory-menu .category-header {
  text-transform: uppercase;
  font-weight: 600;
  position: relative;
}

.hover-subcategory-menu li {
  padding: 5px 0;
}

.hover-subcategory-menu li.subcategory a {
  font-size: 12px;
  font-weight: 500;
}

.hover-subcategory-menu li.active a {
  color: #ffc000 !important;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.products-container h4 {
  margin-top: 0;
  margin-bottom: 30px;
  font-weight: normal;
  text-transform: capitalize;
}

.products-container .product {
  position: relative;
  height: 430px;
  padding-right: 15px;
  margin-bottom: 20px;
  display: inline-block;
  overflow: hidden;
  cursor: pointer;
}

.products-container .product .image-holder {
  height: 165px;
  overflow: hidden;
}

.products-container .product .image-holder img {
  height: 100%;
  width: 100%;
  -webkit-animation: scale-in-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: scale-in-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.products-container .product .image-holder img:hover {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.products-container .product h6 {
  text-transform: uppercase;
  font-weight: normal;
  margin: 15px 0;
  font-size: 14px;
  height: 30px;
}

.products-container .product p.price {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  background: rgba(51, 51, 51, 0.51);
  color: white;
  margin: 0;
  text-align: center;
  margin-bottom: 0;
  width: 260px;
  margin-top: 15px;
  padding: 6px 12px;
}

.products-container .product p.price span {
  color: #f9b409;
}

.products-container .product .product-info {
  padding: 0;
}

.products-container .product .product-info ul {
  height: 100px;
  overflow: visible;
}

.products-container .product .product-info ul li {
  text-transform: uppercase;
  display: block;
  font-size: 13px;
  font-weight: normal;
}

.products-container .product button {
  position: absolute;
  bottom: 21px;
  left: 0;
  margin: 0;
  text-align: center;
  margin-bottom: 0;
  width: 260px;
  margin-top: 15px;
  padding: 6px 12px;
}

.products-container .product.main-page {
  padding: 0 15px;
  transition: all .4s ease-in-out;
}

.products-container .product.main-page .overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: white;
  opacity: 0;
  transition: all .15s ease-in-out;
}

@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(50%, -200px);
    transform: translate(50%, -200px);
    opacity: 1;
  }
}

@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(50%, -200px);
    transform: translate(50%, -200px);
    opacity: 1;
  }
}

.products-container .product.main-page button {
  width: 152px;
  left: 15px;
}

.products-container .product.main-page:hover {
  color: black;
}

.products-container .product.main-page:hover .overlay {
  opacity: 0.5;
  transition: all .15s ease-in-out;
  -webkit-box-shadow: 0px 0px 12px 0px #c4c4c4;
  -moz-box-shadow: 0px 0px 12px 0px #c4c4c4;
  box-shadow: 0px 0px 12px 0px #c4c4c4;
  z-index: 1;
}

.products-container .product.main-page:hover button {
  opacity: 1;
  z-index: 1;
  left: 0;
  bottom: 0;
  transition: all .15s ease-in-out;
  -webkit-box-shadow: 0px 0px 12px 0px #c4c4c4;
  -moz-box-shadow: 0px 0px 12px 0px #c4c4c4;
  box-shadow: 0px 0px 12px 0px #c4c4c4;
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.products-container .product.product-display {
  height: auto;
}

.products-container .product.product-display a {
  margin-top: 4px;
}

.products-container .product.product-display a.small-images {
  overflow: hidden;
  height: 90px;
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.products-container .product.product-display .top-products {
  position: relative;
  margin-bottom: 0;
}

.products-container .product.product-display .top-products .slick-slider {
  z-index: 2;
  margin: 0 40px;
}

.products-container .product.product-display .top-products .top-product {
  display: block;
  outline: none;
  margin: 5px;
  position: relative;
}

.products-container .product.product-display .top-products .top-product .image-holder {
  height: 90px;
  overflow: hidden;
}

.products-container .product.product-display .top-products .top-product .image-holder:hover + p {
  display: block;
}

.products-container .product.product-display .top-products .slick-slide:focus {
  outline: none;
}

.products-container .product.product-display .top-products .carousel-arrows button {
  bottom: 30px;
}

.products-container .product.product-display .top-products .carousel-arrows .slick-arrow {
  position: absolute;
}

.products-container .product.product-display .top-products .carousel-arrows .slick-arrow i {
  padding: 10px;
}

.products-container .product.product-display .top-products .carousel-arrows .slick-arrow.prev {
  left: 0;
}

.products-container .product.product-display .top-products .carousel-arrows .slick-arrow.next {
  right: 0;
}

.products-container .single-product h3 {
  margin-bottom: 30px;
}

.products-container .single-product .product .image-holder {
  height: auto;
}

.products-container .single-product .product .image-holder img {
  -webkit-animation: none;
  animation: none;
}

.products-container .single-product .product .image-holder img:hover {
  -webkit-animation: none;
  animation: none;
}

.products-container .single-product .product .product-info,
.products-container .single-product .product .price {
  display: inline-block;
  font-size: 14px;
  padding-left: 10px;
}

.products-container .single-product .product .product-info ul,
.products-container .single-product .product .price ul {
  line-height: 27px;
}

.products-container .single-product .product .product-info ul li,
.products-container .single-product .product .price ul li {
  font-size: 14px;
}

.products-container .single-product .product .price {
  padding-left: 0;
  background-color: transparent;
  width: auto;
  color: black;
}

.products-container .single-product .product .pricespan {
  color: #f9b409;
}

.products-container .single-product .carousel-arrows {
  display: block;
}

.products-container .single-product .carousel-arrows .slick-arrow {
  position: relative !important;
  display: block;
  bottom: auto;
  width: auto;
}

.products-container .single-product .carousel-arrows .slick-arrow.prev {
  float: left;
}

.products-container .single-product .carousel-arrows .slick-arrow.next {
  float: right;
}

#product.contact-form {
  padding: 0;
}

@media (min-width: 841px) {
  .mobile-nav {
    display: none;
  }
  .mobile-nav .toggle-categories {
    display: none;
  }
}

@media (max-width: 840px) {
  .products .products-container {
    display: block;
    float: none !important;
    margin: 0 auto;
    width: 90%;
  }
}

@media (min-width: 840px) {
  .single-product .carousel-arrows {
    top: auto;
    width: auto;
    bottom: 20px;
  }
  .single-product .carousel-arrows .slick-arrow {
    top: auto;
    bottom: auto !important;
    width: auto;
  }
}

@media (max-width: 768px) {
  .mobile-nav .g-wrapper {
    padding: 0;
  }
  .categories {
    padding-left: 30px;
  }
  .products-container .product {
    height: auto;
    padding-right: auto;
  }
  .products-container .product p {
    font-size: 11px;
  }
  .products-container .product p.price {
    font-size: 14px;
  }
  .products-container .product .product-info ul li {
    font-size: 11px !important;
  }
  .products-container .product button {
    position: unset;
  }
  .single-product {
    margin-top: 30px;
  }
  .single-product .product .price {
    font-size: 11px !important;
    text-align: left;
    padding: 0;
  }
  .single-product .carousel-arrows {
    width: auto;
    top: auto;
    bottom: 0;
  }
  .single-product .carousel-arrows button {
    bottom: 30px;
  }
  .single-product .carousel-arrows .slick-arrow {
    position: absolute;
  }
  .single-product .carousel-arrows .slick-arrow i {
    padding: 10px;
  }
  .single-product .carousel-arrows .slick-arrow.prev {
    left: 0;
  }
  .single-product .carousel-arrows .slick-arrow.next {
    right: 0;
  }
  .products-container .product {
    padding: 15px;
  }
  .products-container .product button {
    right: 0;
  }
  .products-container .product button.prev {
    left: 0;
  }
  .products-container .product button.right {
    right: 0;
  }
  .filters div {
    margin-bottom: 10px;
  }
}

@media (min-width: 768px) {
  .filters div {
    margin-bottom: 10px;
  }
}
