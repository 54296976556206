.gallery-image-holder {
    margin-bottom: 30px;
    a {
        img {
            width: 100%;
            height: auto;

        }
    }
}

@media(min-width: 1000px) {
    .gallery-image-holder {
        a {
            img {
                height: 160px;

            }
        }
    }
}