.contacts {
  margin-top: 150px;
}

.contacts #map-canvas {
  height: 500px;
}

.contacts .contacts-holder {
  overflow: hidden;
}

.contacts .contacts-holder h3 {
  color: black;
  margin-bottom: 30px;
  margin-top: 0;
}

.contacts .contacts-holder p {
  margin: 0;
}

.contacts .contacts-holder .working-hours {
  margin-bottom: 30px;
}

.contacts .contacts-holder ul li {
  margin-bottom: 20px;
}

.contacts .contacts-holder ul li .icon {
  color: #f9b409;
  font-size: 40px;
  margin-right: 20px;
  vertical-align: top;
  display: inline-block;
}

.contacts .contacts-holder ul li .info {
  vertical-align: top;
  display: inline-block;
}

@media (max-width: 768px) {
  .contacts {
    margin-top: 100px;
  }
  .contacts .contacts-holder {
    text-align: center;
  }
  .contacts .contacts-holder ul {
    margin-bottom: 30px;
  }
  .contacts .contacts-holder ul li .icon {
    display: block;
    margin: 0;
  }
  .contacts .contact-form {
    padding: 0 20px;
  }
}
