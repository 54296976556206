.terms {
  margin: 140px auto 50px auto;
}

@media (max-width: 768px) {
  .terms {
    margin: 70px auto;
    padding: 0 30px;
  }
}
