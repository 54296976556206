// main elements
$cubic: cubic-bezier(0.4, 0.01, 0.165, 0.99);
$mukta: 'Ek Mukta', sans-serif;;
$menuItems: 7;

body {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: normal;
    overflow-x: hidden;
}

#top {
    display: none;
    position: fixed;
    // display: block;
    right: 75px;
    bottom: 48px;
    font-size: 42px;
    color: #ffc000;
    z-index: 1000;

    i {
        border: 1px solid #ffffff;
        border-radius: 50px;
        background-color: white;
    }
}

.pagination {
    visibility: hidden;
}

a {
    text-decoration: none;
    color: black;
    cursor: pointer;

    &:hover {
        text-decoration: none;
        color: auto;
        color: #747474;
    }

    &:focus {
        outline: none;
        text-decoration: none;
    }
}

p {
    color: black;
    font-size: 16px;
}

ul {
    display: block;
    overflow: hidden;
    margin-bottom: 0;
    padding-left: 0;
}

li {
    text-decoration: none;
    list-style-type: none;
    float: left;
}

.block {
    display: block;
}


select {
    outline: none;
}

.yellow-line {
    border-top: 1px solid #f9b409;
}

.red {
    color: red;
}

.modal-dialog {
    width: 60%;

    .close {
        font-size: 50px;
        font-weight: lighter;
        opacity: 1;
    }

    .modal-content {
        overflow: hidden;

        .modal-header {
            border: none;
            margin-bottom: 30px;
        }

        h4 {
            text-align: center;
            text-transform: uppercase;
            color: black;
            margin-bottom: 20px;
        }

        .products-container {
            overflow: hidden;
            display: block;
            margin: 0 40px;

            .product {
                height: auto; // padding: 0;
                margin: 0;

                .image-holder {
                    height: auto;
                    width: 100%;
                }

                .product-info {
                    .price {
                        font-size: 12px;
                    }

                    li {
                        font-size: 12px;
                        margin: 10px auto;
                    }
                }
            }
        }

        .modal-footer {
            border: none;

            .contact-form {
                h4 {
                    text-align: left;
                    text-transform: none;
                    font-weight: 200;
                }

                margin: 0 0 50px 0;
                overflow: hidden;
                padding: 0px 35px;

                .btn {
                    margin-left: 15px;
                }
            }
        }
    }
}

.contact-form {
    margin: 10px 0;
    overflow: hidden;
    padding: 0 200px;

    &.calculator {
        margin: 0;

        .form-control {
            padding: 6px 2px;

            &::placeholder {
                font-size: 20px;
            }
        }

        .btn {
            position: relative;
            top: 27px;
            width: auto;
            margin-left: 15px;
        }
    }

    h3 {
        margin: 50px 0 30px 0;
    }

    textarea {
        resize: none;
    }

    .form-control {
        border-radius: 0;
        border-color: #ccc;
        border-width: 0 0 2px 0;
        border-style: none none solid none;
        box-shadow: none;
    }

    .form-control:focus {
        box-shadow: none;
        border-color: #f9b409;
    }

    .js-hide-label {
        opacity: 0;
    }

    .js-unhighlight-label {
        color: #999
    }

    button {
        display: block;
        margin: 0 auto;
        width: 200px;
    }
}

.info-calc{
    font-size: 12px;
    margin-bottom: 20px;
}

.styled-checkbox {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it
    border-radius: 8px;
    outline: none;

    &+label {
        position: relative;
        cursor: pointer;
        padding: 0;
    }

    // Box.
    &+label:before {
        content: '';
        margin-right: 5px;
        display: inline-block;
        vertical-align: middle;
        width: 15px;
        height: 15px;
        background: white;
        border-radius: 5px;
        bottom: 1px;
        position: relative;
    }

    // Box hover
    &:hover+label:before {
        background: #747474;
    }

    // Box focus
    &:focus+label:before {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    // Box checked
    &:checked+label:before {
        background: #747474;
    }

    // Disabled box.
    &:disabled+label:before {
        box-shadow: none;
        background: #ddd;
    }

    // Checkmark. Could be replaced with an image
    &:checked+label:after {
        content: '';
        position: absolute;
        left: 3px;
        top: 11px;
        background: white;
        width: 2px;
        height: 2px;
        box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
        transform: rotate(45deg);
    }
}

// main
.content {
    margin-top: 100px;
}

.main-container {
    width: 90%;
    display: block;
    margin: 0 auto;
    overflow: hidden;
}

.no-padding {
    padding: 0;
}

.bold {
    font-weight: 600;
}

.margin-bottom {
    margin-bottom: 70px;
}

.btn {
    &.main-button {
        background-color: #f9b409;
        color: white;
        border-radius: 1px;
        outline: none;
        font-weight: normal;
        // font-size: 12px;
        font-size: 14px;
        border: 1px solid #f9b409;

        &:hover {
            opacity: 0.8;
        }
    }
}


.info-ribbon {
    background-color: #f9b409;
    // background-color: white;
    position: fixed;
    z-index: 5;
    // font-size: 10px;
    font-size: 17px;
    // padding: 5px 0;
    padding: 1px 0;

    .econt{
        img{
            width: 14px;
            position: relative;
            top: -1px;
        }
    }

    .pull-left {
        .icon {
            margin-right: 3px;
        }

        p {
            display: inline-block;
            margin: 0;
            color: white;
            font-weight: 700;
        }
    }

    .pull-right {
        p {
            margin: 0;
            display: inline-block;

        }
    }

    a {
        color: white;
        margin-right: 10px;
    }
}

.navigation-main {
    background-color: white;
    color: black;
    // padding: 20px 0;
    padding: 10px 0;
    // line-height: 50px;
    line-height: 68px;
    position: fixed;
    z-index: 5;
    top: 26px;
    // top: 34px;
    border-bottom: 1px solid #E3E3E3;

    .logo {
        // width: 100px;
        width: 130px;

    }

    .menu {
        text-align: center;
        // font-size: 16px;
        font-size: 19px;

        li {
            margin: auto 10px;
            display: inline-block;
            float: none;

            a {
                font-weight: bold;

                &:hover {
                    text-decoration: none;
                    color: #747474;
                }

                &.active {
                    border-bottom: 2px solid #f9b409;
                }
            }
        }
    }
}



.search-main {
    overflow: hidden;
    height: 50px;

    .search-input {
        height: 60px;
        font-size: 14px;
        display: inline-block;
        border: none;
        outline: none;
        // color: #555;
        padding: 3px;
        padding-right: 60px;
        width: 0px;
        position: absolute;
        top: 0;
        right: 0;
        background: none;
        z-index: 3;
        transition: width .4s cubic-bezier(0.000, 0.795, 0.000, 1.000);
        cursor: pointer;

        &:focus {
            width: 150px;
            z-index: 1;
            border-bottom: 1px solid #BBB;
            cursor: text;

            &:hover {
                border-bottom: 1px solid #BBB;
            }
        }
    }

    .search-btn {
        width: 63px;
        display: inline-block;
        background: url('../../../img/search-icon.png') center center no-repeat;
        background-size: 23px;
        border: none;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        cursor: pointer;
        transition: opacity .4s ease;
        outline: none;

        &:hover {
            opacity: 0.8;
        }
    }
}

.responsive-menu {
    position: fixed;
    display: none;
    top: 25px;
    left: 0;
    right: 0;
    height: 50px;
    background: white;
    overflow: hidden;
    
    // overflow-x: scroll;
    transition: all 0.5s ease-out, background 1s ease-out;
    transition-delay: 0.2s;
    z-index: 10;
    border-bottom: 1px solid #E3E3E3;

    a {
        position: relative;
        top: 7px;

        .logo {
            width: 60px;
            float: right;
        }
    }

    .burger-container {
        position: relative;
        display: inline-block;
        height: 50px;
        width: 50px;
        cursor: pointer;
        transform: rotate(0deg);
        transition: all 0.3s $cubic;
        user-select: none;
        -webkit-tap-highlight-color: transparent;

        #burger {
            width: 18px;
            height: 8px;
            position: relative;
            display: block;
            margin: -4px auto 0;
            top: 50%;

            .bar {
                width: 100%;
                height: 1px;
                display: block;
                position: relative;
                background: black;
                transition: all 0.3s $cubic;
                transition-delay: 0s;

                &.topBar {
                    transform: translateY(0px) rotate(0deg);
                }

                &.btmBar {
                    transform: translateY(6px) rotate(0deg);
                }
            }
        }
    }

    .icon {
        display: inline-block;
        position: absolute;
        height: 100%;
        line-height: 50px;
        width: 50px;
        height: 50px;
        text-align: center;
        color: #FFF;
        font-size: 22px;
        left: 50%;
        transform: translateX(-50%);

        &.icon-bag {
            right: 0;
            top: 0;
            left: auto;
            transform: translateX(0px);
            transition: transform 0.5s $cubic;
            transition-delay: 0.65s;
        }
    }

    ul.menu {
        position: relative;
        display: block;
        // padding: 0px 48px 0;
        padding: 0px 48px 100px;
        list-style: none;

        li.menu-item {
            display: block;
            border-bottom: 1px solid #f9b409;
            margin-top: 5px;
            transform: scale(1.15) translateY(-30px);
            opacity: 0;
            transition: transform 0.5s $cubic, opacity 0.6s $cubic;

            @for $i from 1 through $menuItems {
                &:nth-child(#{$i}) {
                    transition-delay: #{0.56 - ($i * 0.07)}s;
                }
            }

            a {
                display: block;
                position: relative;
                color: black;
                text-decoration: none;
                font-size: 15px;
                line-height: 3.35;
                font-weight: 500;
                width: 100%;
            }
        }
    }

    &.menu-opened {
        // height: 100vh; 
        height: 100%;
        background-color: white;
        transition: all 0.3s ease-in, background 0.5s ease-in;
        transition-delay: 0.25s;
        z-index: 10;

        .burger-container {
            transform: rotate(90deg);

            #burger {
                .bar {
                    transition: all 0.4s $cubic;
                    transition-delay: 0.2s;

                    &.topBar {
                        transform: translateY(4px) rotate(45deg);
                    }

                    &.btmBar {
                        transform: translateY(3px) rotate(-45deg);
                    }
                }
            }
        }

        ul.menu {
            li.menu-item {
                transform: scale(1) translateY(0px);
                opacity: 1;
                float: none;
                text-align: center;

                @for $i from 1 through $menuItems {
                    &:nth-child(#{$i}) {
                        transition-delay: #{0.07 * $i+0.2}s;
                    }
                }
            }
        }

        .icon {
            &.icon-bag {
                transform: translateX(75px);
                transition-delay: 0.3s;
            }
        }
    }
}

footer {
    font-size: 16px;
    margin-top: 50px;

    .subscribe {
        background-color: #f9b409;
        text-align: center;
        padding: 15px 0;
        background-image: url('../../../img/texture.png');
        background-position: center -160px;
        background-repeat: no-repeat;
        background-size: cover;

        .sign-up {
            font-weight: 600;
        }

        label {
            font-weight: lighter;
            margin-bottom: 0;
        }

        .input-holder {
            width: 40%;
            margin: 10px auto;
            position: relative;

            input {
                border-radius: 8px;
                background-color: white;
                outline: none;
                padding: 10px;
                font-weight: normal;
                -webkit-appearance: none;
                appearance: none;
                border: none;
                width: 100%;
            }

            .sumbit-button {
                position: absolute;
                right: 10px;
                top: 8px;
                background-image: url('../../../img/send.png');
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                width: 25px;
                height: 25px;
                background-color: transparent;
                border: none;
                outline: none;
                appearance: none;
                -webkit-appearance: none;
            }
        }
    }

    .footer-links {
        padding: 40px 0;
        border-bottom: 1px solid #dad7d7;
        margin-bottom: 10px;
        overflow: hidden;

        .border-left {
            border-left: 1px solid #f9b409;
        }

        .newest {
            a {
                display: inline-block;
                // width: 220px;
                // white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .logo {
            // width: 100px;
            // width: 140px;
            width: 190px;
            margin: 0 auto;
            display: block;
        }

        ul {
            margin-bottom: 20px;

            li {
                float: none;

                &.link-heading {
                    color: #f9b409;
                    font-weight: 600;
                    margin-bottom: 10px;
                }

                i {
                    font-size: 16px;
                    margin-right: 5px;
                }
            }
        }
    }

    .foot-rib{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .rights {
            font-size: 12px;
            color: #747474;
            padding: 10px 0;
            &.econt-info{
                img{
                    margin-left: 5px;
                    width: 90px;
                }
            }
        }
    }

}

.arrows {
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 15px;

    &.right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }

    &.left {
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
    }
}

.carousel-arrows {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    cursor: pointer;

    button {
        appearance: none;
        -webkit-appearance: none;
        border: none;
        outline: none;
        background-color: transparent;

        &.prev {
            display: flex;
            align-self: center;
        }

        &.next {
            display: flex;
            align-self: center;
            margin-left: auto;
        }

        i {
            border: solid black;
            border-width: 0 1px 1px 0;
            display: inline-block;
            padding: 15px;

            &.right {
                transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
            }

            &.left {
                transform: rotate(135deg);
                -webkit-transform: rotate(135deg);
            }
        }
    }
}

.blog-admin{
    .blog-item-admin{
        overflow: hidden;
        margin-bottom: 100px;
        border-bottom: 3px solid #fab406;
        padding-bottom: 60px;
        border-top: 3px solid #fab406;
        padding-top: 60px;
        .edit-box{
            display: block;
            overflow: hidden;
        }
    }
}

.g-wrapper{
    margin-top: 150px;
}

// blog
.advice-a{
    &:hover{
        .advice-box-image-holder{
            opacity: 0.7;
        }
    }
}
.advice-box{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 40px;

    .advice-box-image-holder{
        height: 150px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        flex: 0 0 30%;
        margin-right: 10px;
        // border-right: 3px solid #fab406;
        // padding-right: 10px;
    }
    .advice-description{
        flex: 0 0 65%;
        border-left: 3px solid #fab406;
        padding: 10px 0 10px 10px;
        #see-more{
            margin-top: 10px;
            color: #fab406;
            font-size: 12px;
        }
    }
    .info{
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
    }
  
}


@-webkit-keyframes rotate-90-ccw {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(5deg);
        transform: rotate(5deg);
    }
}

@keyframes rotate-90-ccw {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(5deg);
        transform: rotate(5deg);
    }
}

@-webkit-keyframes rotate-back {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
    }
}

@keyframes rotate-back {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
    }
}

@-webkit-keyframes scale-in-ver-top {
    0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
    }

    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
    }
}

@keyframes scale-in-ver-top {
    0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
    }

    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
    }
}

@-webkit-keyframes flip-in-hor-bottom {
    0% {
        -webkit-transform: rotateX(80deg);
        transform: rotateX(80deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: rotateX(0);
        transform: rotateX(0);
        opacity: 1;
    }
}

@keyframes flip-in-hor-bottom {
    0% {
        -webkit-transform: rotateX(80deg);
        transform: rotateX(80deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: rotateX(0);
        transform: rotateX(0);
        opacity: 1;
    }
}

@media(max-width: 1096px) {
    .navigation-main {
        .menu {
            font-size: 14px;

            li {
                margin: auto 5px;
            }
        }

        .logo {
            width: 90px;
        }
    }

    // p{
    //     font-size: 12px;
    // }
}

@media(max-width: 992px) {
    .contact-form {
        &.calculator {
            .btn {
                top: 0;
            }
        }
    }

}

@media(max-width: 840px) {
    h2 {
        font-weight: 600 !important;
        font-size: 16px;
    }

    h5 {
        font-weight: 400 !important;
        font-size: 11px;
    }

    p {
        font-size: 11px;
    }

    .content {
        margin-top: 75px;
    }

    .main-container {
        width: 100%;
    }

    .hide-on-mobile {
        display: none;
    }

    .info-ribbon {
        text-align: center;

        .pull-left {
            float: none !important;

            a {
                margin: 0;
            }

            p {
                &:first-child {
                    float: left;
                    margin-left: 15px;
                }

                &:last-child {
                    float: right;
                    margin-right: 15px;
                }
            }

        }

    }

    .responsive-menu {
        display: block;
        top: 17px;
    }

    .home .head .heading h5 {
        font-size: 14px !important;
    }

    #top {
        right: 10px;
    }

    .modal-dialog {
        width: 100%;

        .products-container {
            margin: 0 10px;

            .product-info {
                margin-top: 20px;
            }
        }

        .modal-footer {
            .contact-form {
                padding: 0 10px;
            }
        }
    }

    .carousel-arrows {
        bottom: 0;
        top: 210px;
        display: block;
        margin: 0 auto;
        width: 100px;

        button {
            display: inline-block !important;

            i {
                padding: 9px;
            }
        }
    }

    footer {
        font-size: 12px;

        .footer-links {
            .border-left {
                border: none;
            }
        }

        .subscribe {
            padding: 10px;
            background-position: center;

            label {
                width: 90%;
            }

            .input-holder {
                width: 90%;
                margin-top: 15px;
                font-size: 11px;
            }
        }

        .rights {
            padding: 10px;
        }

        .newest {
            float: right;
        }
    }

    .advice-box{
        .advice-box-image-holder{
            // flex: 0 0 40%;
            // margin-right: 10px;
            // border-right: 3px solid #fab406;
            // padding-right: 10px;
            flex: 0 0 100%;
            margin-right: 10px;
            margin-bottom: 15px;
        }
        .advice-description{
            // flex: 0 0 55%;
            flex: 0 0 100%;
        }
       
    }
}