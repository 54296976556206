.prices {
    margin-top: 150px;
    margin-bottom: 50px;

    h4 {
        font-weight: normal;
        text-transform: uppercase;
        margin-bottom: 30px;
    }

    p {
        display: none;
    }

    .table-responsive {
        border: none;

        table {
            th {
                font-weight: bolder;
                text-transform: uppercase;
                background-color: #f9b409;
            }

            tr {

                // border-bottom: 1px solid rgba(0, 0, 0, 0.06);
                td {
                    border: none !important;

                    &:last-child {
                        padding-right: 0;
                        padding-left: 0;

                    }
                }
            }
        }
    }

    .prices-list-mobile {
        display: none;

        .price-block {
            margin-bottom: 5px;
            p{
                margin-bottom: 0;
                padding: 2px;
            }
            li{
                &.bold{
                    background-color: #f9b409;
                }
            }
        }
    }

    @media(max-width: 768px) {
        padding: 0 30px;
        margin-top: 80px;

        p {
            display: block;
        }

        table {
            th {
                font-size: 14px;
            }

            td {
                font-size: 12px;
            }
        }

        .prices-list-mobile {
            display: block;
        }
    }
}