.services {
  margin-top: 150px;
}

.services h2 {
  margin: 180px auto 100px auto;
}

.services .mobile-services {
  display: none;
}

.services .service {
  overflow: hidden;
  margin-bottom: 30px;
  position: relative;
}

.services .service .info {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 500px;
}

.services .service .info.left {
  text-align: left;
  float: right;
}

.services .service .info.right {
  text-align: right;
  float: left;
}

.services .service .image {
  background-size: contain;
  background-repeat: no-repeat;
  height: 500px;
  position: absolute;
}

.services .service .image.left {
  background-position: left;
  left: 0;
}

.services .service .image.right {
  background-position: right;
  right: 0;
}

.services .service h3 {
  color: black;
  line-height: 35px;
}

@media (max-width: 768px) {
  .services {
    margin-top: 0;
  }
  .services .mobile-services {
    display: block;
  }
  .services .service {
    position: unset;
  }
  .services .service .info {
    display: block;
    padding: 15px;
    text-align: center;
    height: auto;
    float: none !important;
  }
  .services .service .image {
    height: auto;
    background-position: center !important;
    background-size: cover;
    height: 340px;
    padding: 0;
    position: unset;
    background-position: top !important;
  }
}
